<template>
  <VCard class="px-2 py-2 mb-2">
    <div class="d-flex justify-space-between align-center">
      <VRow no-gutters align="center">
        <VCol
          cols="auto"
          lg="3"
          md="5"
          sm="5"
          class="mr-3"
          xs="4"
          :class="{ 'mb-1': $vuetify.breakpoint.xs }"
        >
          <VTextField
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('form.search')"
            v-model="searchString"
            clearable
            hide-details
            solo
            :loader-height="3"
            @input="onSearch"
            :loading="searchLoading"
            @keypress="validationForTitle($event)"
            @paste="validationForTitle($event)"
          />
        </VCol>
        <VCol cols="auto" lg="3" md="5" sm="5" xs="4" v-if="permissions.can_edit_sector">
          <v-autocomplete
            :items="Object.values(depots)"
            :item-value="'uuid'"
            :item-text="'name'"
            :label="$t('form.storage')"
            :no-data-text="$t('table.noData')"
            dense
            solo
            hide-details
            v-model="depotUuid"
            @change="onSelectSector"
            :menu-props="{ bottom: true, offsetY: true }"
            small-chips
            deletable-chips
            @keypress="validationForWarehouses($event)"
            @paste="validationForWarehouses($event)"
          />
        </VCol>
      </VRow>

      <VCol cols="auto" class="text-right" v-if="permissions.can_create_sector">
        <VBtn
          class="success-bg text-white text-transform-none br-10"
          @click="createSectorDialog = true"
        >
          <VIcon :left="!$vuetify.breakpoint.mdAndDown">mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.mdAndDown">
            {{ $t("sectors.create_sector") }}
          </div>
        </VBtn>
      </VCol>
    </div>
    <VDivider class="mt-3" />
    <v-simple-table class="my-table" :dense="!isLarge">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center xs" v-if="permissions.can_edit_sector">
              <VIcon dense>mdi-cogs</VIcon>
            </th>
            <th class="xs"></th>
            <th class="font-weight-bold">{{ $t("table.name") }}</th>
            <th class="font-weight-bold">{{ $t("table.description") }}</th>
            <th class="font-weight-bold">{{ $t("form.storage") }}</th>
          </tr>
        </thead>
        <tbody name="fade" is="transition-group">
          <SectorRow
            v-for="(sector, index) in sectors"
            :key="sector.uuid"
            :sector="sector"
            :index="index"
            @delete="onDeleteSector"
            @toggleRow="toggleRow"
            :class="{ 'active-row': activeRow == sector.uuid }"
          />
        </tbody>
      </template>
    </v-simple-table>
    <h3 v-if="!sectors.length && !searchLoading">{{ $t("table.noData") }}</h3>
    <v-progress-linear v-if="isLoadingFilter" indeterminate color="primary"></v-progress-linear>
    <!-- <div class="text-center mt-3">
      <VBtn
        depressed
        color="blue"
        outlined
        dark
        v-if="!loadMoreDisabled"
        @click="nextPage"
        class="br-10"
      >
        Завантажити більше
      </VBtn>
    </div> -->
    <div
      v-observer="{ nextPage: nextPage }"
      class="observer"
      v-if="!loadMoreDisabled && !searchLoading"
    ></div>
    <CreateSectorDialog
      v-if="permissions.can_create_sector && createSectorDialog"
      :visible="createSectorDialog"
      :depots="depots"
      @close="createSectorDialog = false"
    />
  </VCard>
</template>

<script>
import depotService from "../../services/request/depot/depotService";
import notifications from "../../mixins/notifications";
import CreateSectorDialog from "./CreateSectorDialog.vue";
import language from "../../mixins/language";
import EventBus from "../../events/EventBus";
import loader from "../../mixins/loader";
import user from "../../mixins/user";
import SectorRow from "./SectorRow.vue";
import paginationMixin from "../../mixins/paginationMixin";
import {
  uniqueByUUID,
  validationForTitle,
  validationForWarehouses
} from "../../mixins/helpers";
import _ from "lodash";
import SizeUi from "../../mixins/SizeUi";
import {mapActions, mapGetters} from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";

export default {
  name: "SectorsComponent",
  components: {
    CreateSectorDialog,
    SectorRow
  },
  data: () => ({
    sectors: [],
    createSectorDialog: false,
    activeView: "list",
    searchString: "",
    depots: [],
    searchLoading: false,
    loadMoreDisabled: false,
    depotUuid: "",
    activeRow: "",
    isLoadingFilter: false,
    loadTime: 0
  }),
  mixins: [notifications, language, loader, user, paginationMixin, SizeUi],
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    nextPageDisabled() {
      return this.sectors.length < this.perPage;
    }
  },
  methods: {
    validationForWarehouses,
    validationForTitle,
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    toggleRow(e) {
      this.activeRow = e;
    },
    async emitPageChange() {
      try {
        this.reorderNumbers();
        this.isLoadingFilter = true;
        await this.getSectorsList();
        this.isLoadingFilter = false;
      } catch (e) {
        console.log(e)
        this.isLoadingFilter = false;
      }
    },
    async onSelectSector() {
      try {
        this.setLoading(true);
        this.page = 1;
        const params = {};
        if (this.searchString) {
          params.name = this.searchString;
        }
        if (this.depotUuid) {
          params.uuid_deport = this.depotUuid;
          this.sectors = await depotService.getSectorsList({
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            ...params,
            uselike: true,
            order: "DESC",
            orderby: "time_created"
          });
        }
        else {
          // this.sectors = await depotService.getSectorsList({
          //   limit: this.perPage,
          //   offset: (this.page - 1) * this.perPage,
          //   ...params,
          //   uselike: true,
          //   order: "DESC",
          //   orderby: "time_created"
          // });
          this.getSectorsList()
        }
        this.loadMoreDisabled = this.sectors.length < this.perPage;
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    onSearch:  _.debounce(async function(updated) {
      if (this.searchString) {
        this.page = 1;
        const params = {};
        if (this.depotUuid) {
          params.uuid_deport = this.depotUuid;
        }
        this.sectors = await depotService.getSectorsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          name: this.searchString,
          uselike: true,
          ...params,
          order: "DESC",
          orderby: "time_created"
        });
      } else {
        const params = {};
        if (this.depotUuid) {
          params.uuid_deport = this.depotUuid;
        }
        if (!updated) {
          this.page = 1;
        }
        params.offset = updated ? 0 : (this.page - 1) * this.perPage;
        params.limit = updated ? this.largeLimit : this.perPage;
        this.sectors = await depotService.getSectorsList({
          ...params,
          uselike: true,
          order: "DESC",
          orderby: "time_created"
        });
      }
      this.loadMoreDisabled = this.sectors.length < this.perPage;
    }, 500),
    async getSectorsList(params) {
      try {
        this.searchLoading = true;
        const newItems = await depotService.getSectorsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...params,
          order: "DESC",
          orderby: "time_created"
        });
        this.sectors.push(...newItems);
        // orderDialog(this.sectors);
        this.sectors = uniqueByUUID(this.sectors);
        // orderDialog(this.sectors);
        this.loadMoreDisabled = newItems.length < this.perPage;
        if (!this.sectors) this.sectors = [];
        this.searchLoading = false;
        this.setLoading(false);
        this.reorderNumbers();
      } catch (e) {
        this.searchLoading = false;
        this.setLoading(false);
        console.log(e)
      }
    },
    async onDeleteSector(uuid) {
      try {
        this.page = 1;
        this.setLoading(true);
        await depotService.deleteDepot(uuid);
        this.setLoading(false);
        this.setSuccessNotification(this.$t('sectors.sector_was_deleted_successfully'));
        await this.getSectorsList();
        this.sectors = await depotService.getSectorsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          order: "DESC",
          orderby: "time_created"
        });
        this.loadMoreDisabled = this.sectors.length < this.perPage;
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async fetchFirstPage() {
      try {
        // this.page = 1;
        this.setLoading(true);
        this.sectors = await depotService.getSectorsList({
          limit: this.perPage * this.page,
          offset: 0,
          order: "DESC",
          orderby: "time_created"
        });
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    }
  },
  async mounted() {
    EventBus.$on("sector-created", async () => {
      // await this.fetchFirstPage();
      await this.onSearch();
    });
    EventBus.$on("sector-updated", async () => {
      // await this.fetchFirstPage();
      await this.onSearch(true);
    });
    try {
      await this.getSectorsList();
      this.setLoading(true);
      this.depots = await depotService.getDepotsList();
      if (!this.depots) this.depots = [];
      this.setLoading(false);
    } catch (e) {
      this.setLoading(false);
      console.log(e)
    }
    this.checkLoadTime();
  }
};
</script>

<style lang="scss">
.pagination-items {
  &-header,
  &-row {
    > div {
      &:nth-child(1) {
        text-align: center;
        width: 5%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 25%;
      }
      &:nth-child(5) {
        text-align: center;
        width: 15%;
      }
    }
  }
}
</style>
