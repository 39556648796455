<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    @click:outside="showConfirmDialog"
    :persistent="isChanged"
    content-class="cross__dialog"
    scrollable
    :transition="$dialogTransition"
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? 'auto' : ''">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          $t("sectors.create_sector")
        }}</span>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <div class="text-center" v-if="loading">
            <VProgressCircular :size="50" color="primary" indeterminate></VProgressCircular>
          </div>
          <div class="" v-else>
            <VRow>
              <VCol cols="12">
                <VTextField
                  outlined
                  dense
                  :label="$t('form.name')"
                  v-model="sector.name"
                  required
                  :error-messages="titleErrors"
                  hide-details="auto"
                  color="success"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                ></VTextField>
              </VCol>
              <VCol cols="12">
                <v-autocomplete
                  :items="Object.values(depots)"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  outlined
                  :label="$t('form.storage')"
                  :no-data-text="$t('table.noData')"
                  dense
                  v-model="sector.deport_uuid"
                  :error-messages="uuidErrors"
                  hide-details="auto"
                  deletable-chips
                  small-chips
                  color="success"
                  item-color="success"
                  @keypress="validationForWarehouses($event)"
                  @paste="validationForWarehouses($event)"
                />
              </VCol>
              <VCol cols="12">
                <VTextarea
                  outlined
                  dense
                  :label="$t('form.description')"
                  required
                  v-model="sector.description"
                  no-resize
                  counter
                  maxlength="255"
                  rows="2"
                  color="success"
                  @keypress="validationForDepartment($event)"
                  @paste="validationForDepartment($event)"
                ></VTextarea>
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol col="6">
            <VBtn
              dark
              block
              class="text-white text-transform-none br-10 w-100"
              @click="visibility = false"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol col="6">
            <VBtn
              block
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="onCreate"
              :disabled="!isChanged"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.create") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import depotService from "../../services/request/depot/depotService";
import EventBus from "../../events/EventBus";
import {
  validationForDepartment,
  validationForTitle,
  validationForWarehouses
} from "@/mixins/helpers";

export default {
  name: "CreateSectorDialog",
  validations: {
    sector: {
      name: { required },
      deport_uuid: { required }
    }
  },
  components: {
    ConfirmLeftDialog
  },
  mixins: [language, validationMixin, notifications],
  props: {
    visible: {
      required: true
    },
    depots: {
      required: true
    }
  },
  watch: {
    role: "updateRoleInfo",
    sector: {
      deep: true,
      handler() {
        const values = Object.values(this.sector);
        if (values.filter(item => item).length) {
          this.isChanged = true;
        } else {
          this.isChanged = false;
        }
      }
    }
  },
  data: () => ({
    sector: {
      name: "",
      description: "",
      deport_uuid: ""
    },
    loading: false,
    visibleConfirm: false,
    isChanged: false
  }),
  methods: {
    validationForWarehouses,
    validationForTitle,
    validationForDepartment,
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.sector = [];
      this.$v.$reset();
      this.visibility = false;
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("name", this.sector.name);
          formData.append("description", this.sector.description);
          formData.append("uuid_deport", this.sector.deport_uuid);
          await depotService.addSector(formData);
          this.loading = false;
          this.$v.$reset();
          this.setSuccessNotification(this.$t('sectors.sector_was_created_successfully'));
          this.clearSector();
          this.visibility = false;
          EventBus.$emit("sector-created");
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
    clearSector() {
      Object.keys(this.sector).forEach(key => {
        this.sector[key] = "";
      });
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.sector.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.sector.name.required && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    uuidErrors() {
      const errors = [];
      if (!this.$v.sector.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.sector.deport_uuid.required &&
        errors.push(this.$t('form.errors.StorageRequired'));
      return errors;
    }
  }
};
</script>

<style scoped></style>
